import React from "react"
import styled from "styled-components/macro"
import { rem } from "polished"
import { motion } from "framer-motion"
import { useAnalytics } from "core/hooks"
import { PageTransition } from "core/components"
import { PageContainer, SplitLayout, breakpoint } from "core/layout"
import { Heading, AltHeading, ScrollToTop, SocialFooter } from "elements"
import { AwardsSection, WorksGrid, MainIntro } from "modules"
import TextReveal from "elements/animate/TextReveal"
import PageLayout from "../templates/PageLayout"
import { graphql } from "gatsby"

const Section = styled.section`
  margin: ${rem(40)} 0;

  ${breakpoint.tablet`
    margin: ${rem(120)} 0;
  `};

  ${breakpoint.laptop`
    margin: ${rem(160)} 0;
  `};
`

const StyledInfo = styled(motion.div)`
  margin-top: ${rem(40)};

  h3 {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }
`

const MarginOverride = styled.div`
  ${breakpoint.tablet`
    > div {
      margin-top: 0;
      margin-bottom: ${rem(80)};
    }
  `};

  ${breakpoint.laptop`
    > div {
      margin-bottom: ${rem(120)};
    }
  `};
`

const Ins = styled.ins`
  position: static !important;

  ${breakpoint.laptop`
    position: absolute !important;
  `};
`

function Info(props) {
  const { index, title, text } = props
  return (
    <StyledInfo>
      <TextReveal animate={{ delay: index * 0.2, spring: { damping: 50 } }}>
        <AltHeading>
          {title}
          <span>.</span>
        </AltHeading>
      </TextReveal>

      <TextReveal
        animate={{
          delay: index * 0.25,
          spring: { damping: 25, stiffness: 75 },
        }}
      >
        <p>{text}</p>
      </TextReveal>
    </StyledInfo>
  )
}

export default function Home({ location, data }) {
  useAnalytics({
    title: "home",
    path: location.pathname,
  })

  if (!data.strapi) {
    return (
      <PageLayout>
        <PageTransition>
          <PageContainer>
            <Section>
              <Heading>Empty page</Heading>
            </Section>
          </PageContainer>
        </PageTransition>
      </PageLayout>
    )
  }

  const { homepage, abouts, awards, workShowcases } = data.strapi

  const aboutRows = [
    { isHeading: true } /* <Heading> placeholder */,
    ...abouts,
  ].reduce((array, component, index) => {
    const rowIndex = Math.floor(index / 2)
    const colIndex = index % 2
    if (!array[rowIndex]) {
      array[rowIndex] = []
    }
    array[rowIndex][colIndex] = component
    return array
  }, [])

  // izveido masīvu no titleAbout teksta, kurš ir iekavās
  // ar šī palīdzību strapi var noteikt nosvītroto un jauno tekstu
  var textString = homepage.titleAbout
  var reBrackets = /\((.*?)\)/g
  var listOfText = []
  var found
  while (!!(found = reBrackets.exec(textString))) {
    listOfText.push(found[1])
  }

  return (
    <PageLayout>
      <PageTransition>
        <MainIntro video={homepage.introVideo} />

        <PageContainer>
          <Section>
            {aboutRows.map((row, index) => {
              const [leftCol, rightCol] = row
              return (
                <SplitLayout
                  key={`row_${index + 1}`}
                  left={() =>
                    leftCol.isHeading ? (
                      <Heading style={{ marginBottom: 0 }}>
                        {listOfText[0]}
                        <br />
                        <nobr>
                          <del>{listOfText[1]}</del> <Ins>{listOfText[2]}</Ins>
                        </nobr>{" "}
                        {listOfText[3] && listOfText[3]}
                      </Heading>
                    ) : (
                      <Info
                        index={index + 1}
                        title={leftCol.title}
                        text={leftCol.description}
                      />
                    )
                  }
                  right={() => {
                    const Wrapper = leftCol.isHeading
                      ? MarginOverride
                      : React.Fragment
                    return (
                      <Wrapper>
                        {rightCol && (
                          <Info
                            index={index + 2}
                            title={rightCol.title}
                            text={rightCol.description}
                          />
                        )}
                      </Wrapper>
                    )
                  }}
                />
              )
            })}
          </Section>

          <Section>
            <WorksGrid
              title={homepage.titleWorks}
              cards={workShowcases}
              action={{ label: "See more projects", to: "/works" }}
            />
          </Section>

          <AwardsSection props={awards} title={homepage.titleAwards} />
          <ScrollToTop currentId="home" />
        </PageContainer>

        <SocialFooter />
      </PageTransition>
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    strapi {
      homepage {
        introVideo {
          url
        }
        titleAbout
        titleAwards
        titleWorks
      }

      abouts {
        id
        title
        description
      }

      awards {
        id
        title
        awardImage {
          url
          imageFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }

      workShowcases(
        limit: 6
        sort: "order:desc"
        where: { showOnHomePage: "true" }
      ) {
        id
        slug
        title
        description
        color
        showcaseImage {
          url
          imageFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        showcaseImageMobile {
          url
          imageFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`
