import React from "react"
import { motion, useTransform } from "framer-motion"
import { useAnimate } from "core/hooks"

export default function TextReveal({
  children,
  style,
  animate: animateOptions,
}) {
  const [ref, animate] = useAnimate(animateOptions)
  const y = useTransform(animate, [0, 1], [48, 0])

  return (
    <motion.div ref={ref} style={{ ...style, y, opacity: animate }}>
      {children}
    </motion.div>
  )
}
